<template>
  <div id="main">
    <DFSHeader />
    <div id="main-content">
      <a-alert
        message="禁止访问"
        type="error"
        description="受限资源，禁止访问。"
        show-icon
      />
    </div>
    <DFSFooter />
  </div>
</template>

<script>
import DFSHeader from '@/components/DFSHeader';
import DFSFooter from '@/components/DFSFooter';


export default {
  name: 'PermissionDenied',
  components: {
    DFSHeader,
    DFSFooter,
  },
}
</script>

<style lang="stylus" scoped>
#main-content
  padding-top: 1rem
</style>
